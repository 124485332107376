const Sliderdata = [
  {
    image: "https://i.ibb.co/LJWcNhB/IMG-6208-1.jpg",
    heading: "WELCOME TO CCC SHARON PARISH",
    desc: "Celestial Church of Christ Sharon parish was birthed on the 1st of May, 2003. the idea had been conceived a few years back when the evangelist-in-charge, senior evangelist S.O. Sofowora was having a personal mediation in the word of God.",
    link: "/new",
  },
  {
    image: "https://i.ibb.co/YdDXZCT/Slider3.jpg",
    heading: "WELCOME TO CCC SHARON PARISH",
    desc: "Celestial Church of Christ Sharon parish was birthed on the 1st of May, 2003. the idea had been conceived a few years back when the evangelist-in-charge, senior evangelist S.O. Sofowora was having a personal mediation in the word of God.",
    link: "/new",
  },

  {
    image: "https://i.ibb.co/3FPb8Dj/Slider1.jpg",
    heading: "WELCOME TO CCC SHARON PARISH",
    desc: "Celestial Church of Christ Sharon parish was birthed on the 1st of May, 2003. the idea had been conceived a few years back when the evangelist-in-charge, senior evangelist S.O. Sofowora was having a personal mediation in the word of God.",
    link: "/new",
  },
  {
    image: "https://i.ibb.co/0qVRVSj/Slider5.jpg",
    heading: "WELCOME TO CCC SHARON PARISH",
    desc: "Celestial Church of Christ Sharon parish was birthed on the 1st of May, 2003. the idea had been conceived a few years back when the evangelist-in-charge, senior evangelist S.O. Sofowora was having a personal mediation in the word of God.",
    link: "/new",
  },
];

export default Sliderdata;
